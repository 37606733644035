<template>
  <main>
    <section class="section">
      <div class="container">
        <div class="chain">
          <div v-if="product.category !== ''" class="chain__item">{{ product.category }} /</div>
          <div v-if="product.subCategory !== ''" class="chain__item">{{ product.subCategory }} /</div>
          <div v-if="product.subSubCategory && product.subSubCategory !== ''" class="chain__item">
            {{ product.subSubCategory }} /
          </div>
          <div v-if="product.filters" class="chain__item chain__item--last">{{ filterList }}</div>
        </div>
        <div class="product">
          <div v-if="product.photos && product.photos.length > 1" class="product__img-wrap">
            <vue-agile ref="main" :options="options1" :as-nav-for="asNavFor1" class="main">
              <div v-for="img in product.photos" :key="img.ref" class="slide">
                <img :src="img.url" :alt="product.productId" class="" />
              </div>
            </vue-agile>

            <vue-agile
              ref="thumbnails"
              :options="options2"
              :as-nav-for="asNavFor2"
              :slides-to-show="product.photos.length > 4 ? 4 : product.photos.length"
              class="thumbnails"
            >
              <div
                v-for="(img, index) in product.photos"
                :key="img.ref"
                class="slide slide--thumbnail"
                @click="$refs.thumbnails.goTo(index)"
              >
                <img :src="img.url" :alt="product.productId" class="" />
              </div>
            </vue-agile>
          </div>

          <div
            v-else-if="product.photos && product.photos.length === 1"
            class="product__img-wrap product__img-wrap--single"
          >
            <img :src="product.photos[0].url" alt="product image" class="img product__img" />
          </div>

          <div v-else class="product__img-wrap product__img-wrap--single">
            <img src="@/assets/images/products.png" alt="product image" class="img product__img" />
          </div>

          <div class="product__info">
            <div v-if="product.isBestSeller">
              <div class="product__badge"><i class="ri-award-fill"></i>Bestseller</div>
            </div>
            <h1 class="product__title">{{ product.product }}</h1>
            <h3 class="product__subtitle">{{ product.productId }}</h3>
            <div v-if="product.accessories && product.accessories.length" class="accessories">
              <h3 class="accessories__title">Accessories</h3>
              <ul class="accessories__list">
                <li v-for="acc in accessories" :key="acc.accessoryId" class="accessories__item">
                  <div class="accessories__name">{{ acc.accessory }}</div>
                  <div class="accessories__subtitle">{{ acc.accessoryId }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="loader">
        <loader
          object="#ffffff"
          color1="#ffffff"
          color2="#ffffff"
          size="5"
          speed="1"
          bg="#636669"
          objectbg="#FFE248"
          opacity="100"
          disableScrolling="false"
          name="spinning"
        ></loader>
      </div>
    </section>
  </main>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {VueAgile} from 'vue-agile'

export default {
  components: {VueAgile},
  data() {
    return {
      sliderOptions: {
        dots: false,
        navButtons: false,
        infinite: false
      },
      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: false,
        fade: true,
        navButtons: false
      },
      options2: {
        autoplay: false,
        centerMode: true,
        dots: false,
        navButtons: false
      },
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      product: s => s.products.product
    }),
    accessories() {
      return this.product.accessories
    },
    filterList() {
      return this.product.filters.join(', ')
    }
  },
  created() {
    window.scrollTo(0, 0)
  },
  async mounted() {
    this.isLoading = true
    const id = this.$route.params.id
    await this.fetchProduct(id)
    this.isLoading = false
    this.asNavFor1.push(this.$refs.thumbnails)
    this.asNavFor2.push(this.$refs.main)
  },
  methods: {
    ...mapActions({
      fetchProduct: 'products/fetchProduct'
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
